.heading > h1 {
  text-align: center;
  color: #da1919d0;
  font-family: "regular";
  font-size: 40px;
}
.heading {
  padding-top: 100px;
}
.sub_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  width: 80vw;
  margin: auto;
  margin-top: 20px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  align-items: center;
  padding-left: 5%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.home_background {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
}
.sub_container1 {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  height: 250px;
  margin-bottom: 20px;
}
.sub_container2 > h3 {
  color: #da1919d0;
  font-family: "medium";
  margin-bottom: 20px;
}
.sub_container2 {
  flex: 3;
  padding: 2%;
}
.logo {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 20px;
}
.logo_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content > h5 {
  color: #da1919d0;
  font-family: "medium";
}
.content > p {
  color: #6e8b7c;
  font-family: "regular";
}
.form_subcontainer {
  display: flex;
  flex-direction: column;
}
label {
  color: #da1919d0;
  font-family: "medium";
  margin-bottom: 10px;
}
input {
  width: 60%;
  margin-bottom: 20px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #6e8b7c;
  color: #6e8b7c;
  font-family: "regular";
  height: 30px;
}
input:focus {
  outline: none;
}
.form_container {
  margin-bottom: 20px;
}
.send_button {
  color: #fff;
  border: none;
  background-color: #da1919d0;
  border-radius: 4px;
  height: 30px;
  width: 90px;
  font-family: "regular";
}
@media screen and (max-width: 900px) {
  .sub_container {
    flex-direction: column;
  }
  .sub_container1 {
    height: 600px;
    flex: 1;
    width: 90%;
  }
  .sub_container2 {
    flex: 1;
    width: 90%;
    margin-top: 40px;
  }
}
