.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.01);
  padding-top: 120px;
}
.sub_container {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 80%;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 50px;
}
.card_container {
  width: 80%;
  margin: auto;
  margin-bottom: 40px;
}
.container > h1 {
  color: #da1919d0;
  font-family: "regular";
}
@media screen and (max-width: 700px) {
  .sub_container {
    width: 90%;
  }
  .card_container {
    width: 90%;
  }
}
