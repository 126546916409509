.home_background {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
}
.home_text {
  height: 99vh;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.main_heading {
  font-size: 100px;
  font-weight: 400;
  font-family: "semiBold";
  color: #da1919d0;
  text-shadow: 0.5px 1px black;
}
.sub_heading {
  font-size: 30px;
  font-weight: 400;
  font-family: "medium";
}
.home_button {
  font-family: "regular";
  width: 150px;
  height: 30px;
  align-self: center;
  margin-top: 70px;
  color: #fff;
  background-color: rgb(218, 40, 40);
  border: none;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 600px) {
  .main_heading {
    font-size: 50px;
    margin-bottom: 30px;
  }
  .sub_heading {
    font-size: 20px;
    font-weight: 400;
    font-family: "medium";
  }
}
