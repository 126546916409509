.about__image {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  object-fit: cover;
}
.about__founder {
  display: flex;
  justify-content: center;
}
.about__img {
  height: 200px;
  width: 200px;
  border-radius: 100%;
}
.about_container {
  padding-top: 100px;
  padding-bottom: 100px;
}
.about_us {
  text-align: center;
  position: relative;
  font-size: 40px;
  font-family: "Open Sans", sans-serif;
  color: #bc1414;
  font-weight: bolder;
  text-shadow: px 2px 8px #f0c9c9;
}
.about__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  flex-wrap: wrap;
}
.about_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
  flex-wrap: wrap;
}
.about__content > h1 {
  font-size: 50px;
  color: #bc1414;
  font-family: "medium";
  margin-left: 40px;
}
.about__content > p {
  color: #6e8b7c;
  font-family: "regular";
  font-size: 50px;
}
.line {
  width: 1px;
  height: 120px;
  background-color: gray;
  margin-right: 20px;
}
.about_content > h2 {
  flex: 1;
  text-align: center;
  color: #bc1414;
  font-size: 30px;
  padding: 8px;
  font-family: "semiBold";
  margin-left: 20px;
}
.about_content > ul > li {
  letter-spacing: 1px;
  font-size: 20px;
  color: #6e8b7c;
  font-family: "regular";
}
.about_content > ul {
  list-style: disc;
  flex: 2;
  margin-left: 40px;
}
.about_content > p {
  margin-right: 40px;
  flex: 2;
  letter-spacing: 1px;
  font-size: 20px;
  padding: 8px;
  color: #6e8b7c;
  font-family: "regular";
}
@media screen and (max-width: 800px) {
  .about_content > p {
    text-align: center;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .about_content {
    flex-direction: column;
  }
  .about__img {
    height: 150px;
    width: 150px;
    border-radius: 100%;
  }
  .about__content > h1 {
    font-size: 35px;
    color: #bc1414;
    font-family: "medium";
    margin-left: 40px;
  }
  .about__content > p {
    color: #6e8b7c;
    font-family: "regular";
    font-size: 20px;
  }
  .about_content > h2 {
    margin-bottom: 5px;
  }
  .line {
    width: 30%;
    height: 1px;
    margin: auto;
    margin-bottom: 15px;
  }
}
