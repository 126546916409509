.container {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
}
.container > h1 {
  color: #da1919d0;
  font-family: "medium";
  text-align: center;
}
.container > p {
  color: #6e8b7c;
  font-family: "regular";
  font-size: 25px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  margin-bottom: 40px;
}
.player {
  margin-bottom: 50px;
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
}
.subPlayer {
  margin-right: 50px;
  max-width: 300px;
  margin-left: 30px;
}
.subPlayer > h1 {
  color: #da1919d0;
  font-family: "medium";
}
.subPlayer > p {
  color: #6e8b7c;
  font-family: "regular";
  margin-top: 10px;
  width: 400px;
}
@media screen and (max-width: 700px) {
  .player {
    flex-direction: column;
    padding: 10px;
  }
  .subPlayer {
    margin-left: 0px;
  }
  .container > p {
    margin-right: 20px;
    margin-left: 20px;
  }
}
