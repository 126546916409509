.Navbar {
  background: rgba(255, 255, 255, 0.5);
  height: 80px;
  width: 100%;
  position: absolute;
  z-index: 999;
  opacity: 0.9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  float: right;
}
.Navbar .nav__logo {
  width: 60px;
  object-fit: contain;
}
.NavLink .nav__container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 1px solid black;
}
.NavLinks {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}
.Navbar nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  float: right;
  max-width: 100%;
}
.Navbar nav ul li {
  display: inline-block;
  margin: 1.5vw;
  font-size: 28px;
  text-align: center;
}
.Navlink:hover {
  font-size: 30px;
}
.Navlink {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-family: "regular";
  font-size: 20px;
  transition: all 2s;
}
.activeLink {
  text-decoration: none;
  color: #da1919d0;
  font-family: "regular";
  font-size: 20px;
  transition: all 1s;
}

.Navbar .Mobilenav {
  display: none;
}
.logo {
  position: absolute;
  top: 8px;
  left: 30px;
  height: 50px;
  width: 200px;
  object-fit: contain;
}
@media (max-width: 800px) {
  .logo {
    display: none;
  }
}
@media (max-width: 600px) {
  .Navbar {
    background: white;
  }
  .Navbar .Navigation {
    display: none;
  }
  .Navbar .Mobilenav {
    display: flex;
    align-items: center;
  }
  .Navbar .Mobilenav .nav__logo {
    position: relative;
  }
  .Navbar .Mobilenav .Hamburger {
    position: absolute;
    right: 5%;
    cursor: pointer;
  }

  .Navbar nav ul li {
    margin-bottom: 50px;
  }
  .Navbar nav ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    background: white;
    padding-top: 100px;
  }
}
